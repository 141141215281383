<script>
import appConfig from "@/app.config";
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Voucher ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      loading: true,
      item: [],
      newTotals: [],
      totalUsd: 0,
      totalEur: 0,
      totalTry: 0,
      totalDays: 0,
    };
  },
  created() {
    this.getData();
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    // Farklı gün sayısını bulmak için
    uniqueDaysCount() {
      const uniqueDays = new Set(this.item.tourRoutes.map(reservation => reservation.date));
      return uniqueDays.size;
    },
    
    // Farklı araç plakası sayısını bulmak için
    uniqueCarsCount() {
      const uniquePlates = new Set(this.item.tourRoutes.map(reservation => reservation.vehicle.plate));
      return uniquePlates.size;
    }
  },
  methods: {
    getData() {
      const id = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/reservation/" + id;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.item = response.data?.data;
          const totals = response.data?.data.totals;
          const totalsTour = response.data?.data.totalsTour;
          // İki array'i birleştir
          let combinedArray = [...totals, ...totalsTour];

          this.item.reservations.forEach((reservation) => {
            reservation.night = this.calculateNight(
              reservation.first_date,
              reservation.last_date,
              reservation.room_count
            );
            this.totalDays += reservation.night;
          });

          // currency değeri 1 olanları filtrele ve topla
          let totalAmountCurrency1 = combinedArray
            .filter((item) => item.currency === 1)
            .reduce(
              (total, item) => total + parseInt(item.total_sell_amount),
              0
            );

          let totalAmountCurrency2 = combinedArray
            .filter((item) => item.currency === 2)
            .reduce(
              (total, item) => total + parseInt(item.total_sell_amount),
              0
            );

          let totalAmountCurrency3 = combinedArray
            .filter((item) => item.currency === 3)
            .reduce(
              (total, item) => total + parseInt(item.total_sell_amount),
              0
            );

          this.totalUsd = totalAmountCurrency2;
          this.totalEur = totalAmountCurrency3;
          this.totalTry = totalAmountCurrency1;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateNight(firstDate, lastDate, room_count) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDateObj = new Date(firstDate);
      const lastDateObj = new Date(lastDate);

      const night = Math.round(Math.abs((firstDateObj - lastDateObj) / oneDay));
      const total = (night * room_count)
      return total;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<template>
  <div>
    <b-button
      variant="outline-secondary"
      class="position-absolute"
      style="
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000000;
      "
      @click="generateReport"
    >
      <i class="fa fa-print"></i> PDF Oluştur
    </b-button>
    <vue-html2pdf
      ref="html2Pdf"
      :enable-download="false"
      :filename="`voucher-${item.id}`"
      :show-layout="true"
      :float-layout="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <aside id="body" class="container-fluid position-relative">
          <aside class="row mb-3">
            <aside class="col-12 text-center">
              <img
                src="@/assets/images/logo-fluid.png"
                alt="Reem Travel"
                height="100"
              />
            </aside>
          </aside>
          <aside class="row mt-4">
            <div class="col-lg-4">
              <b-card header-class="bg-transparent">
                <h5 class="card-title mt-0">Customer Information</h5>
                <p class="card-text">
                  <b>Name: </b> {{ item.customer.customer_name }}<br />
                  <b>Reservation No: </b> #{{ item.id }}<br />
                  <b>Date: </b>
                  {{ item.created_at | moment("DD.MM.YYYY HH:mm") }}
                </p>
              </b-card>
            </div>
            <div class="col-lg-4">
              <b-card header-class="bg-transparent">
                <h5 class="card-title mt-0">Agency Information</h5>
                <p class="card-text">
                  <b>Name: </b> {{ item.agency.company_name }}<br />
                </p>
              </b-card>
            </div>
          </aside>
          <aside class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="table-responsive mb-0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Hotel</th>
                        <th>Type Room</th>
                        <th>Room Count</th>
                        <th>Board</th>
                        <th>Adult</th>
                        <th>Child</th>
                        <th>Baby</th>
                        <th>Check-In</th>
                        <th>Check-Out</th>
                        <th>Night</th>
                        <th>Confirmation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="reservation in item.reservations"
                        :key="reservation.id"
                      >
                        <td>{{ reservation.hotel.brand_name }}</td>
                        <td>{{ reservation.room.room_name }}</td>
                        <td>{{ reservation.room_count }}</td>
                        <td>{{ reservation.board.label }}</td>
                        <td>{{ reservation.adult_count }}</td>
                        <td>{{ reservation.children_count }}</td>
                        <td>{{ reservation.baby_count }}</td>
                        <td>
                          {{ reservation.first_date | moment("DD.MM.YYYY") }}
                        </td>
                        <td>
                          {{ reservation.last_date | moment("DD.MM.YYYY") }}
                        </td>
                        <td>{{ reservation.night }}</td>
                        <td>{{ reservation.confirme_number }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </aside>
          <aside class="row">
            <aside class="col-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title text-center">Services</h4>
                </div>
                <div class="table-responsive mb-0">
                  <table class="table table-striped">
                    <thead></thead>
                    <tbody>
                      <!-- Gün ve Araç Sayısı Gösterimi -->
                      <tr v-if="item.tourRoutes.length > 0">
                        <td>Tour/Transfer</td>
                        <td>{{ uniqueDaysCount }}</td> <!-- Farklı günlerin sayısı -->
                      </tr>
                      <tr v-if="item.tourRoutes.length > 0">
                        <td>Car</td>
                        <td>{{ uniqueCarsCount }}</td> <!-- Farklı araç plakalarının sayısı -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </aside>
            <aside class="col-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title text-center">Cost</h4>
                </div>
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr v-if="this.totalUsd > 0">
                        <td class="table-reem">Total Cost (USD):</td>
                        <td>{{ this.totalUsd }} $</td>
                      </tr>
                      <tr v-if="this.totalEur > 0">
                        <td class="table-reem">Total Cost (EUR):</td>
                        <td>{{ this.totalEur }} €</td>
                      </tr>
                      <tr v-if="this.totalTry > 0">
                        <td class="table-reem">Total Cost (TRY):</td>
                        <td>{{ this.totalTry }} ₺</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </aside>
          </aside>
        </aside>
      </section>
    </vue-html2pdf>
  </div>
</template>

<style scoped lang="scss">
#body,
body {
  background-color: #fff !important;
}
.card {
  background-color: #f1f5f7 !important;
}
thead {
  background-color: #acdfda;
}
tbody {
  &:nth-child(2n) {
    background-color: rgba(172, 223, 218, 0.2);
  }
}
.table-reem {
  background-color: #acdfda;
}

.card-header {
  background: #acdfda;
}
</style>

<style>
.vue-html2pdf .layout-container {
  background: none !important;
}
body {
  background-color: #fff !important;
}
</style>
